export default class HeroVideo {
  constructor(videoSelector = '.hero__video') {
    try {
      if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
        console.log('[HeroVideo] `prefers-reduced-motion` detected. Video paused.');
      } else {
        const video = document.querySelector(videoSelector);

        if (!video) return;

        window.addEventListener('load', () => { video.play(); });

        this.setupAutoStopObserver(video);
      }
    } catch {
      console.warn('[HeroVideo] No video container detected on page');
    }
  }

  /**
   * Stop/start the video when it's out of frame.
   */
  setupAutoStopObserver(video) {
    let isPaused = false;
    const observer = new IntersectionObserver(
      (entries, _) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio != 0.2 && !video.paused) {
            video.pause();
            isPaused = true;
          } else if (isPaused) {
            video.play();
            isPaused = false;
          }
        });
      },
      { threshold: 0.2 },
    );
    observer.observe(video);
  }
}
