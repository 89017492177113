/**
 * Initializes the menu functionality for the specified site navigation element.
 * It sets up the necessary variables and utility functions, and optionally generates an overlay.
 *
  NOTES: We are always adding overlay to the mobile menu
 * @returns {void}
  TODO: Refactor all the event listeners in a separate function which would allow for removing the unnecesary ones when the menu is not in use.
  An example of that would be:

  const eventListeners = [];

  const addEventListeners = () => {
    const pointerDownListener = (e) => handleNonMenuClick(e);
    const keyDownListener = (e) => handleEscapeKey(e);
    document.addEventListener("pointerdown", pointerDownListener);
    document.addEventListener("keydown", keyDownListener);
    toggleHamburgerButton.addEventListener("click", handleToggleHamburgerButton);
    
    eventListeners.push( element: document, event: "pointerdown", listener: pointerDownListener);
    eventListeners.push( element: document, event: "keydown", listener: keyDownListener);
    eventListeners.push( element: toggleHamburgerButton, event: "click", listener: handleToggleHamburgerButton);
    .
    .
    .
    }
  */

const Menu = () => {
  const DESKTOP_BREAKPOINT = 1024;

  const siteNavigation = document.getElementById("site-navigation");
  if (!siteNavigation) return;

  const menuContainer = siteNavigation.querySelector(".menu-container");
  const navWrapper = siteNavigation.querySelector(".top-menu__nav-wrapper");
  const menuItems = menuContainer.querySelectorAll(".menu-item");
  const subMenuItems = menuContainer.querySelectorAll(".sub-menu");
  const spacerDiv = document.querySelector(".spacer");
  const toggleHamburgerButton = siteNavigation.querySelector(".menu-toggle");
  // This is used to hide the noised background when the main menu is open in ablet
  const menuToggleWrapper = siteNavigation.querySelector(
    ".menu-toggle__wrapper",
  );

  // State variables
  let toggleButtonActive = false;
  let overlayActive = false;
  let subMenuActive = false;

  // Create the element for the overlay
  const navOverlay = document.createElement("div");
  navOverlay.classList.add("nav-overlay");

  /**
   * Remove class from HTMLElemnt or NodeList
   * @param {HTMLElement | NodeList | String} element
   * @param {string} className
   * @return {void}
   */
  const removeClass = (element, className) => {
    if (
      !element instanceof NodeList &&
      !element instanceof HTMLElement &&
      !element instanceof String
    ) {
      return;
    }
    if (element instanceof NodeList) {
      element.forEach((el) => {
        el.classList.remove(className);
      });
    }

    if (element instanceof HTMLElement) {
      element.classList.remove(className);
    }

    if (element instanceof String) {
      element = document.querySelectorAll(element);
      removeClass(element, className);
    }
  };

  const activateButton = () => {
    resetMenu();
    showMenu();
    toggleHamburgerButton.setAttribute("aria-expanded", "true");
    toggleButtonActive = true;

    if (!subMenuActive) {
      menuToggleWrapper.classList.add("open");
    } else {
      menuToggleWrapper.classList.remove("open");
    }
  };

  const deactivateButton = () => {
    resetMenu();
    toggleHamburgerButton.setAttribute("aria-expanded", "false");
    removeClass(menuToggleWrapper, "open");
    toggleButtonActive = false;
  };

  const resetMenu = () => {
    removeClass(menuItems, "show");
    menuContainer.classList.remove("show");
    spacerDiv.classList.remove("show");
  };

  const showMenu = () => {
    removeClass(menuItems, "show");
    menuContainer.classList.add("show");
    spacerDiv.classList.add("show");
  };

  const closeMenu = () => {
    resetMenu();
    deactivateButton();
    deactivateOverlay();
    removeClass(menuToggleWrapper, "open");
  };

  const activateOverlay = () => {
    document.body.classList.add("no-scroll");
    siteNavigation.insertAdjacentElement("afterend", navOverlay);
    overlayActive = true;
  };

  const deactivateOverlay = () => {
    resetMenu();
    document.body.classList.remove("no-scroll");
    navOverlay.remove();
    overlayActive = false;
  };

  const handleDropdowns = () => {
    menuItems.forEach((menuItem) => {
      const subMenu = menuItem.querySelector(".sub-menu");

      if (navWrapper && subMenu) {
        menuItem.addEventListener("click", (e) => {
          // Clear/close all other dropdowns
          subMenuItems.forEach((subMenuItem) => {
            if (subMenuItem !== subMenu) {
              removeClass(subMenuItem, "show");
            }
          });
          // This class is used to add the open close animations
          navWrapper.classList.toggle("close");

          subMenu.classList.toggle("show");
          menuToggleWrapper.classList.toggle("open");

          if (subMenu.classList.contains("show")) {
            subMenuActive = true;
          } else {
            subMenuActive = false;
          }
        });
      }
    });
  };

  const handleNonMenuClick = () => {
    document.addEventListener("pointerdown", (e) => {
      if (
        !siteNavigation.contains(e.target) ||
        e.target.classList.contains("navbar")
      ) {
        if (window.innerWidth < DESKTOP_BREAKPOINT) {
          deactivateButton();
          deactivateOverlay();
        }

        removeClass(navWrapper, "close");
        removeClass(menuToggleWrapper, "open");
        // Makes sure the animation is triggered again when the menu is closed
        removeClass(subMenuItems, "show");
      }
    });
  };

  const handleToggleHamburgerButton = () => {
    toggleHamburgerButton.addEventListener("click", function () {
      !toggleButtonActive ? activateButton() : deactivateButton();
      overlayActive ? deactivateOverlay() : activateOverlay();
    });
  };

  const handleEscapeKey = () => {
    document.addEventListener("keydown", (e) => {
      // The second check prevent the escape key from opening the menu.
      if (e.key === "Escape" && document.body.classList.contains("no-scroll")) {
        closeMenu();
      }
    });
  };

  const init = () => {
    handleToggleHamburgerButton();
    handleDropdowns();
    handleNonMenuClick();
    handleEscapeKey();
  };

  init();
};

export default Menu;
