
/**
 * Initializes the accordion functionality.
 */
const init = () => {
  // Get all of the accordion triggers on the page.
  const accordions = document.querySelectorAll('[data-accordion-trigger]');

  /**
   * Process click events to show/hide accordions panels within that section.
   *
   * @param element The accordion title that was clicked.
   */

  if (accordions) {
    const handleClick = (event: PointerEvent) => {
      const element = event.currentTarget as HTMLElement;
      const parentAccordion = document.querySelector('[data-accordion]');
      const parentAccordionContainer = element.closest('[data-accordion-container]');
      const accordionContainers = document.querySelectorAll('[data-accordion-container]')
  
      // Get the current accordional toggle state before it's morphed.
      const currentAccordionValue = parentAccordionContainer.getAttribute('data-accordion-container');
      const newAccordionValue = currentAccordionValue === 'true' ? 'false' : 'true';
  
      // Hide all of the accordion panels in the section.
      accordionContainers.forEach(accordion => {
        accordion.setAttribute('data-accordion-container', 'false');
      })
  
      // Show the panel that was clicked.
      parentAccordionContainer.setAttribute('data-accordion-container', newAccordionValue);
    }
  
    // Add trigger events for the accordions triggers on the page.
    accordions.forEach(element => {
      element.addEventListener('click', handleClick)
    })
  }  
}

// Allow the function to be exported so it can be consumed in Storybook.
export default init;
