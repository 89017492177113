const startMarquee = () => {
  if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    // eslint-disable-next-line no-console
    console.log('[LargeSeparator] `prefers-reduced-motion` detected. Text separator paused.');
    return;
  }

  const element: HTMLElement = document.querySelector('[data-large-text-marquee]');
  if (!element) {
    return;
  }

  let i = 0;
  let step = 6;
  element.style.position = 'absolute';
  const width = (element.clientWidth + 1);
  element.style.position = '';

  const go = () => {
    i = i < width ? i + step : 1;
    element.style.marginLeft = `${-i}px`;
  };

  element.addEventListener('mouseenter', () => {
    step = 2;
  });

  element.addEventListener('mouseleave', () => {
    step = 6;
  });

  setInterval(go, 50);
};

const init = () => {
  window.addEventListener('DOMContentLoaded', startMarquee);
}

export default init;
