import { breakpoints } from "./constants";

const wrapperClass = 'o-filter-bar';

const FilterBar = () => {
  const wrapper = document.querySelector(`.${wrapperClass}`);
  const searchWrapper = document.querySelector(`.${wrapperClass}__search`);
  const tabletBreakpoint = "(max-width: " + breakpoints.TABLET_BREAKPOINT + ")";
    // window.matchMedia(breakpoint);

  const toggleMobileNav = () => {
    const menuDetails = wrapper.querySelectorAll(`.${wrapperClass}__details`);
    const isOpen = wrapper.classList.contains('menu-open');

    wrapper.classList.toggle('menu-open');
    document.documentElement.classList.toggle('no-scroll');

    menuDetails.forEach((menuDetail) => {
      menuDetail.open = !isOpen;
    });
  }

  const toggleSearch = () => {
    const icon = searchWrapper.querySelector(`.${wrapperClass}__icon`);
    const input = searchWrapper.querySelector('input');
    const isOpen = wrapper.classList.contains('search-open');
    if (isOpen) {
      const defaultValue = input.getAttribute('data-default');
      input.value = defaultValue ? defaultValue : '';
    }

    wrapper.classList.toggle('search-open');
    icon.setAttribute('aria-label', isOpen ? 'Close Search' : 'Open Search');
  }

  const checkWindowSize = () => {
    const isMobileNavOpen = wrapper.classList.contains('menu-open');
    if (window.innerWidth > breakpoints.TABLET_BREAKPOINT_PX && isMobileNavOpen) {
      toggleMobileNav();
    }
  }

  const initSearchEvents = () => {
    const searchIcon = searchWrapper.querySelector(`.${wrapperClass}__search-icon__glass`);
    const closeIcon = searchWrapper.querySelector(`.${wrapperClass}__search-icon__close`);

    searchIcon.addEventListener('click', () => {
      if (wrapper.classList.contains('search-open')) {
        const form = wrapper.querySelector('form');
        const input = searchWrapper.querySelector('input');
        const defaultValue = input.getAttribute('data-default');
        if (form && input.value !== defaultValue) {
          form.submit();
        } else {
          toggleSearch();
        }
      } else {
        toggleSearch();
      }
    });
    closeIcon.addEventListener('click', () => toggleSearch());
  }

  const initMobileEvents = () => {
    const menuButton = wrapper.querySelector(`.${wrapperClass}__combined-menu`);
    const closeButton = wrapper.querySelector(`.${wrapperClass}__header-mobile__close`);

    menuButton.addEventListener('click', toggleMobileNav);
    closeButton.addEventListener('click', toggleMobileNav);
    window.addEventListener('resize', checkWindowSize);
  }

  if(searchWrapper) {
    initSearchEvents();
    initMobileEvents();
  }
};

export default FilterBar;
