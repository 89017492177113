import { breakpoints } from './constants';

const controlState = (wrapper: HTMLElement, controller: HTMLInputElement, video: HTMLVideoElement) => {
  controller.checked = true;
  video.muted = true;
  let firstClick = true;
  wrapper.addEventListener('click', () => {
    if (firstClick) {
      controller.checked = false;
      video.pause();
      video.currentTime = 0;
      video.play();
      video.muted = false;
      firstClick = false;
    } else if (video.paused) {
      controller.checked = false;
      video.play();
    } else {
      controller.checked = true;
      video.pause();
    }
  });
};

const playVideoOnBreakpoints = (video: HTMLVideoElement) => {
  const breakpoint = `(max-width: ${breakpoints.DESKTOP_BREAKPOINT})`;
  const mobileView = window.matchMedia(breakpoint);

  if (mobileView.matches) {
    video.setAttribute('poster', video.getAttribute('data-poster')!);
  } else {
    video.play();
  }
};

const init = () => {
  document.addEventListener('DOMContentLoaded', () => {
    const wrapper: HTMLElement = document.querySelector('.hero-video__controller-wrapper');
    const controller: HTMLInputElement = document.querySelector('.hero-video__controller');

    if (wrapper && controller) {
      const video = wrapper.getElementsByTagName('video')[0];
      controlState(wrapper, controller, video);
      playVideoOnBreakpoints(video);
    }
  });
}

// Allow the function to be exported so it can be consumed in Storybook.
export default init;
