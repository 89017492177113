/**
 * Noise
 * @param {string} noise
 */
export default class Noise {
  constructor(noise) {
    (function (window, doc) {
      function grained(ele, opt) {
        let element = null;
        let elementId = null;
        let selectorElement = null;

        if (typeof ele === 'string') {
          element = doc.getElementsByClassName(ele.split('.')[1]);
        }
        let item;
        for (item = 0; item < element.length; item++) {
          if (element[item].style.position !== 'absolute') {
            element[item].style.position = 'relative';
          }

          element[item].style.overflow = 'hidden';
          elementId = element[0].className;
        }

        const prefixes = ['', '-moz-', '-o-animation-', '-webkit-', '-ms-'];

        // default option values
        const options = {
          animate: true,
          patternWidth: 100,
          patternHeight: 100,
          grainOpacity: 0.15,
          grainDensity: 1,
          grainWidth: 1,
          grainHeight: 1,
          grainChaos: 0.5,
          grainSpeed: 1,

        };

        Object.keys(opt).forEach((key) => {
          options[key] = opt[key];
        });

        const generateNoise = function () {
          const canvas = doc.createElement('canvas');
          const ctx = canvas.getContext('2d');
          canvas.width = options.patternWidth;
          canvas.height = options.patternHeight;
          for (let w = 0; w < options.patternWidth; w += options.grainDensity) {
            for (let h = 0; h < options.patternHeight; h += options.grainDensity) {
              const rgb = Math.random() * 256 | 0;
              ctx.fillStyle = `rgba(${[rgb, rgb, rgb, options.grainOpacity].join()})`;
              ctx.fillRect(w, h, options.grainWidth, options.grainHeight);
            }
          }
          return canvas.toDataURL('image/png');
        };

        function addCSSRule(sheet, selector, rules, index) {
          let ins = '';
          if (selector?.length || 0) {
            ins = `${selector}{${rules}}`;
          } else {
            ins = rules;
          }
          if ('addRule' in sheet) {
            sheet.addRule(selector, rules, index);
          } else if ('insertRule' in sheet) {
            sheet.insertRule(ins, index);
          }
        }

        const noise = generateNoise();

        let animation = '';
        const keyFrames = ['0%:-10%,10%', '10%:-25%,0%', '20%:-30%,10%', '30%:-30%,30%', '40%::-20%,20%', '50%:-15%,10%', '60%:-20%,20%', '70%:-5%,20%', '80%:-25%,5%', '90%:-30%,25%', '100%:-10%,10%'];

        let pre = prefixes.length;
        while (pre--) {
          animation += `@${prefixes[pre]}keyframes grained{`;
          for (let key = 0; key < keyFrames.length; key++) {
            const keyVal = keyFrames[key].split(':');
            animation += `${keyVal[0]}{`;
            animation += `${prefixes[pre]}transform:translate(${keyVal[1]});`;
            animation += '}';
          }
          animation += '}';
        }

        // add animation keyframe
        const animationAdded = doc.getElementById('grained-animation');
        if (animationAdded) {
          animationAdded.parentElement.removeChild(animationAdded);
        }
        let style = doc.createElement('style');
        style.type = 'text/css';
        style.id = 'grained-animation';
        style.innerHTML = animation;
        doc.body.appendChild(style);

        // add custimozed style
        const styleAdded = doc.getElementById(`grained-animation-${elementId}`);
        if (styleAdded) {
          styleAdded.parentElement.removeChild(styleAdded);
        }

        style = doc.createElement('style');
        style.type = 'text/css';
        style.id = `grained-animation-${elementId}`;
        doc.body.appendChild(style);

        let rule = `background-image: url(${noise});`;
        rule += 'position: absolute;content: "";height: 300%;width: 300%;left: -100%;top: -100%;';
        pre = prefixes.length;
        if (options.animate) {
          while (pre--) {
            rule += `${prefixes[pre]}animation-name:grained;`;
            rule += `${prefixes[pre]}animation-iteration-count: infinite;`;
            rule += `${prefixes[pre]}animation-duration: ${options.grainChaos}s;`;
            rule += `${prefixes[pre]}animation-timing-function: steps(${options.grainSpeed}, end);`;
          }
        }
        if ((elementId || '').includes('noise')) {
          selectorElement = '.' + 'noise' + '::before';
        }
        if ((elementId || '').includes('noise-low')) {
          selectorElement = '.' + 'noise-low' + '::before';
        }
        addCSSRule(style.sheet, selectorElement, rule);
      }

      window.grained = grained;
      // END
    }(window, document));
  }
}
